<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ countryCodeComputed }}</div>
    <!--    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedWidgetCustomContent }}</div>-->
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtPhone',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    getCountryList() {
      return this.$store.state.components.countryList
    },
    countryCodeComputed() {
      let findItem = this.getCountryList.find((item) => {
        return item.itemCode === this.computedWidgetCustomContent
      })
      if (findItem) {
        return findItem.cnName
      }
      else {
        return this.computedWidgetCustomContent
      }
    },
  },
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "component.scss";
</style>
